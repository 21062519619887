:root {
  --pure-sky: #00b2ff;
  --color-error: #f1323e;
  --game-layout-res: 1280px;
  --ultra-wide: 1600px;
  --full-hd: 1280px;
  --raffle-layout-large: 1024px;
  --raffle-layout-medium: 800px;
  --raffle-layout-small: 600px;
  --raffle-layout-xs: 300px;
  --copper-color: #f2c394;
  --raffle-border-grey-color: #272d36;
  --raffle-text-sky-color: #e9f4ff;
  --raffle-text-transparent-color: #e9f4ff59;
  --raffle-dark-background-color: #181e30;
  --raffle-card-border-color: #1d223480;
  --raffle-pages-y-padding: 50px;
  --raffle-containers-radius: 16px;
  --raffle-inputs-border-width: 0.07rem;
  --raffle-inputs-border-height: 1px;
  --bloody-mary: #ff3355;
  --global-border-grey: #2a3046;
  --cloudy: #7e8eb880;
  --ghosty: #c8d7f50d;
  --dark-ghosty: #1d223480;
  --dark-foggy-night-100: #1d2234;
  --rainy-morning: #1b2035;
  --dark-rainy-morning: #2a3046;
  --global-bg-dark: #10131a;
  --global-bg-dark-1: #141722;
  --dark-space: #181e30;
  --flame: #de3c52;
  --clouds-100: #abbadb;
  --daylight-color: #d5e0f5;
  --daylight-50-color: #c8d7f580;
  --global-bg-dark-2: #181e30;
  --Dark-Space-Blue: #131635;
  --toxic-color: #b4ff54;
  --toxic-color-dark: #5ebd3d;
  --toxic-color-light: #92e02f;
  --toxic-dark: #93e02f80;
  --bloody-color: #880808;
  --toxic-red-color: #ff0505;
  --daylight-75-color: #c8d7f5bf;
  --text-transparent-color: #c8d7f5bf;
  --input-disabled: #494c68;
  --yellow: #f2c94c;
  --green: #36ea7b;
  --pure-sky-color: #00b2ff0d;

  --font-family-golos: "Golos Text";
  --font-family-exo: "Exo";

  --border-radius-large: 16px;
  --border-radius-medium: 12px;
  --border-radius-small: 8px;
  --border-rad-sm: 8px;

  --header-height: 72px;
  --mobile-header-height: 142px;
  --sidebar-width: 264px;

  --base-text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

  --inactive-btn: linear-gradient(0deg, rgba(126, 142, 184, 0.5), rgba(126, 142, 184, 0.5)),
    linear-gradient(
      279.12deg,
      rgba(152, 175, 249, 0) 27.34%,
      rgba(182, 198, 252, 0.21) 36.76%,
      rgba(181, 198, 255, 0.54) 45.45%,
      rgba(161, 180, 242, 0.22) 55.06%,
      rgba(122, 147, 232, 0) 63.03%
    );
}
