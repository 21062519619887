.swiper-wrapper {
    padding-bottom: 30px;
}

.swiper-slide {
    display: flex;
    align-self: end;
    min-height: 100%;
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
    display: flex;
    width: fit-content;
    height: fit-content;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -100%);
    padding: 6px;
}

.swiper-pagination-bullet {
    z-index: 1;
    background-color: rgba(200, 215, 245, 0.10);
    height: 6px;
    width: 6px;
    border-radius: 50%;
    margin: 0;
}

.swiper-pagination-bullet-active {
    background: var(--pure-sky);
    width: 24px;
    border-radius: 18px;
}